.Controls, .Controls div {
    width: 250px;
    display: flex;
    justify-content: center;
}

.Controls button {
    height: 40px;
    border-radius: 40px;
    border: 0;
    flex: 1;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    position: relative;
    outline: 0;
    transition: all .3s;
    padding: 0;
}

.Controls button.start {
    background: #ffffff;
    color: rgb(0, 0, 0);
}

.Controls button.start:hover {
    background: #cdb3ae;
    color: rgb(255, 255, 255);
    border: 0px solid #fff;
}

.Controls button.reset {
    background: transparent;
    flex: 0;
    margin-right: 25px;
    color: rgb(255, 255, 255);
}

.Controls button.reset:hover {
    color: #666;
}

.Controls button.pause {
    background: #272727;
    color: #fff;
}

.Controls button.pause:hover {
    background: #000000;
}

.Controls button.resume {
    background: #cdb3ae;
    color: #fff;
}

.Controls button.resume:hover {
    background: #c1a099;
}
